import { createContext, useContext } from "react";
import useWebSocket                  from "react-use-websocket";

import {
	getDeviceType,
	getAppVersion,
	getDeviceId,
	getUserData
} from "_common/utils/user";
import { configs } from "_config/index";
// import { ReadyState }                from "react-use-websocket";

const WebSocketContext = createContext ();

export const useRTCWebSocketContext = () => useContext ( WebSocketContext );

const RTCWebSocketProvider = ( { children } ) => {
	const userAuth   = getUserData ();
	const deviceType = getDeviceType ();
	const appVersion = getAppVersion ();
	const deviceId   = getDeviceId ();

	const { sendJsonMessage/* , readyState*/ } = useWebSocket (
		configs.ROOTER_RTC,
		{
			queryParams: {
				token: `Bearer ${ userAuth?.accessToken }`,
				appVersion,
				deviceId,
				deviceType
			},
			onError         : () => { },
			onOpen          : () => { },
			onClose         : () => { },
			onMessage       : () => { },
			shouldReconnect : () => true
		} );

	/* const connectionStatus = {
    [ReadyState.CONNECTING]     : "Connecting",
    [ReadyState.OPEN]           : "Open",
    [ReadyState.CLOSING]        : "Closing",
    [ReadyState.CLOSED]         : "Closed",
    [ReadyState.UNINSTANTIATED] : "Uninstantiated"
  }[readyState];

  useLogger ( "Socket Status ================= :", [ connectionStatus ] ); */

	const sendMessage = ( { event, data } ) => {
		sendJsonMessage ( {
			event,
			data: {
				authToken: `Bearer ${ userAuth?.accessToken }`,
				...data
			}
		} );
	};

	return (
		<WebSocketContext.Provider value = { { sendMessage } }>
			{children}
		</WebSocketContext.Provider>
	);
};

export default RTCWebSocketProvider;
